.helloGo {
  color: #ffffff;
  background-color: #1C869B;
}
.songName {
  color: #005ba6;
}
.songName:hover {
  color: #ffffff;
  background-color: #005ba6;
  cursor: pointer;
}
.songName[tabindex]:focus {
  color: #ffffff;
  background-color: #005ba6;
}
ul {
  float: left;
}
.searchByNameInput {
  width: 200px;
}