body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

td {
  border: 1px ridge #cccccc;
  text-align: center;
}

.tbl {
  width: 100%;
  horiz-align: right;
}

.caption {
  font-size: 30px;
}

.tblHead {
  border: 1px ridge #cccccc;
  text-align: center;
}

.tdLabels {
  width: 40%;
  border: 1px ridge #cccccc;
  text-align: left;
  vertical-align: text-top;
}

.tdReview {
  width: 15%;
  border: 1px ridge #cccccc;
  text-align: center;
  vertical-align: middle;
}
