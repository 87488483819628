.collapsed {
  display: none;
}
.container {
  margin: 0 auto auto;
  width: 100%;
}
.tbl {
  width: 100%;
  horiz-align: right;
}
.tdLabels {
  width: 15%;
  border: 1px ridge #cccccc;
  text-align: left;
  vertical-align: text-top;
}
.labelItem:hover {
  color: #ffffff;
  background-color: #005ba6;
  cursor: pointer;
}
.tdNames {
  width: 20%;
  color: #005ba6;
  border: 1px ridge #cccccc;
  text-align: left;
  vertical-align: top;
}
.tdPic {
  width: 50%;
  border: 1px ridge #cccccc;
  text-align: left;
  vertical-align: top;
}
.tdGroup {
  width: 15%;
  border: 1px ridge #cccccc;
  text-align: left;
  vertical-align: top;
}
.songGroup {
  color: #000000;
  width: 100%;
}
.songGroupItem:hover {
  color: #ffffff;
  background-color: #005ba6;
  cursor: pointer;
}
.songLabelSelect {
  width: 200px;
}